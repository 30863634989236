import { render, staticRenderFns } from "./bank-accounts.vue?vue&type=template&id=4a27e36f&scoped=true"
import script from "./bank-accounts.vue?vue&type=script&lang=js"
export * from "./bank-accounts.vue?vue&type=script&lang=js"
import style0 from "./bank-accounts.vue?vue&type=style&index=0&id=4a27e36f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a27e36f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,CoreInputTextField: require('/app/components/core/input/TextField.vue').default,CoreButton: require('/app/components/core/Button.vue').default})
