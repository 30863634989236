import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0b3d7db6&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b3d7db6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSvgIcon: require('/app/components/core/SvgIcon.vue').default,PublicTetherIcon: require('/app/components/public/tether/Icon.vue').default,DashboardCard: require('/app/components/dashboard/Card.vue').default,DashboardQuickAccess: require('/app/components/dashboard/QuickAccess.vue').default,PublicCreditCardRial: require('/app/components/public/credit-card/Rial.vue').default,PublicCreditCardTether: require('/app/components/public/credit-card/Tether.vue').default,SsrCarousel: require('/app/node_modules/vue-ssr-carousel/index.js').default})
